import { CONFIRMATION_REQUIRED } from 'Component/MyAccountCreateAccount/MyAccountCreateAccount.config';
import MyAccountQuery from 'Query/MyAccount.query';
import {
    CUSTOMER as SOURCE_CUSTOMER,
    MyAccountDispatcher as SourceMyAccountDispatcher,
    ONE_MONTH_IN_SECONDS as SOURCE_ONE_MONTH_IN_SECONDS
} from 'SourceStore/MyAccount/MyAccount.dispatcher';
import {
    sendMembershipData,
    updateCustomerSignInStatus,
    updateIsLoading
} from 'Store/MyAccount/MyAccount.action';
import { showNotification } from 'Store/Notification/Notification.action';
import { hideActiveOverlay } from 'Store/Overlay/Overlay.action';
import {
    setAuthorizationToken
} from 'Util/Auth';
import { getGuestQuoteId, setGuestQuoteId } from 'Util/Cart';
import history from 'Util/History';
import { fetchMutation, getErrorMessage } from 'Util/Request';

export const CartDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Cart/Cart.dispatcher'
);

export const WishlistDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Wishlist/Wishlist.dispatcher'
);

export const ProductCompareDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/ProductCompare/ProductCompare.dispatcher'
);

// TODO: implement CUSTOMER
export const CUSTOMER = SOURCE_CUSTOMER;

// TODO: implement ONE_MONTH_IN_SECONDS
export const ONE_MONTH_IN_SECONDS = SOURCE_ONE_MONTH_IN_SECONDS;

/** @namespace Frontend/Store/MyAccount/Dispatcher */
export class MyAccountDispatcher extends SourceMyAccountDispatcher {
    createAccountForTagManager(option) {
        return option;
    }

    /* funzione per callback tagmanager ^^^ */

    /**
     * Create account action
     * @param {{customer: Object, password: String}} [options={}]
     * @memberof MyAccountDispatcher
     */
    createAccount(options = {}, dispatch) {
        const { customer: { email }, password } = options;
        const mutation = MyAccountQuery.getCreateAccountMutation(options);
        dispatch(updateIsLoading(true));

        this.createAccountForTagManager(options);

        return fetchMutation(mutation).then(
            /** @namespace Frontend/Store/MyAccount/Dispatcher/MyAccountDispatcher/createAccount/fetchMutation/then */
            (data) => {
                const { createCustomer: { customer } } = data;
                const { confirmation_required } = customer;

                if (confirmation_required) {
                    dispatch(updateIsLoading(false));

                    return CONFIRMATION_REQUIRED;
                }

                return this.signIn({ email, password }, dispatch);
            },

            /** @namespace Frontend/Store/MyAccount/Dispatcher/MyAccountDispatcher/createAccount/fetchMutation/then/catch */
            (error) => {
                dispatch(updateIsLoading(false));
                dispatch(showNotification('error', getErrorMessage(error)));
                return false;
            }
        );
    }

    /**
     * Sign in action
     * @param {{email: String, password: String}} [options={}]
     * @memberof MyAccountDispatcher
     */
    async signIn(options = {}, dispatch) {
        const mutation = MyAccountQuery.getSignInMutation(options);

        const result = await fetchMutation(mutation);
        const { generateCustomerToken: { token } } = result;

        setAuthorizationToken(token);

        ProductCompareDispatcher.then(
            ({ default: dispatcher }) => dispatcher.assignCompareList(dispatch)
        );

        const cartDispatcher = (await CartDispatcher).default;
        const guestCartToken = getGuestQuoteId();
        // if customer is authorized, `createEmptyCart` mutation returns customer cart token
        const customerCartToken = await cartDispatcher.createGuestEmptyCart(dispatch);

        if (guestCartToken && guestCartToken !== customerCartToken) {
            // merge guest cart id and customer cart id using magento capabilities
            await cartDispatcher.mergeCarts(guestCartToken, customerCartToken, dispatch);
        }

        setGuestQuoteId(customerCartToken);
        cartDispatcher.updateInitialCartData(dispatch, true);

        WishlistDispatcher.then(
            ({ default: dispatcher }) => dispatcher.updateInitialWishlistData(dispatch)
        );

        await this.requestCustomerData(dispatch);

        dispatch(updateCustomerSignInStatus(true));
        dispatch(updateIsLoading(false));
        dispatch(hideActiveOverlay());
        dispatch(showNotification('success', __('You are successfully logged in!')));

        history.push('/customer/account/');

        return true;
    }

    sendMemberFields(fields, dispatch) {
        dispatch(sendMembershipData(fields));
    }
}

export default new MyAccountDispatcher();
